/*!*****************************************************

freak flags, copyright ©2024 michael p. cohen. freak flags is licenced under the mit licence.  

for complete information visit: www.freakflagsprite.com 

******************************************************/

.flag {
  background-image: url(./flagSprite.png);
  background-repeat: no-repeat;
  background-size: 100% 49494%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  box-sizing: content-box;
}
.flag-ch,
.flag-np {
  box-shadow: none !important;
}
.flag-dz {
  background-position: center 0.2287%;
}
.flag-ao {
  background-position: center 0.4524%;
}
.flag-bj {
  background-position: center 0.6721%;
}
.flag-bw {
  background-position: center 0.8958%;
}
.flag-bf {
  background-position: center 1.1162%;
}
.flag-bi {
  background-position: center 1.3379%;
}
.flag-cm {
  background-position: center 1.5589%;
}
.flag-cv {
  background-position: center 1.7805%;
}
.flag-cf {
  background-position: center 2.0047%;
}
.flag-td {
  background-position: center 2.2247%;
}
.flag-cd {
  background-position: left 2.4467%;
}
.flag-dj {
  background-position: left 2.6674%;
}
.flag-eg {
  background-position: center 2.8931%;
}
.flag-gq {
  background-position: center 3.1125%;
}
.flag-er {
  background-position: left 3.3325%;
}
.flag-et {
  background-position: center 3.5542%;
}
.flag-ga {
  background-position: center 3.7759%;
}
.flag-gm {
  background-position: center 4.0015%;
}
.flag-gh {
  background-position: center 4.2229%;
}
.flag-gn {
  background-position: center 4.441%;
}
.flag-gw {
  background-position: left 4.66663%;
}
.flag-ci {
  background-position: center 4.8844%;
}
.flag-ke {
  background-position: center 5.1061%;
}
.flag-ls {
  background-position: center 5.3298%;
}
.flag-lr {
  background-position: left 5.5495%;
}
.flag-ly {
  background-position: center 5.7712%;
}
.flag-mg {
  background-position: center 5.994%;
}
.flag-mw {
  background-position: center 6.2156%;
}
.flag-ml {
  background-position: center 6.4363%;
}
.flag-mr {
  background-position: center 6.658%;
}
.flag-mu {
  background-position: center 6.8805%;
}
.flag-yt {
  background-position: center 7.1038%;
}
.flag-ma {
  background-position: center 7.3231%;
}
.flag-mz {
  background-position: left 7.5448%;
}
.flag-na {
  background-position: left 7.7661%;
}
.flag-ne {
  background-position: center 7.98937%;
}
.flag-ng {
  background-position: center 8.2099%;
}
.flag-cg {
  background-position: center 8.4316%;
}
.flag-re {
  background-position: center 8.6533%;
}
.flag-rw {
  background-position: right 8.875%;
}
.flag-sh {
  background-position: center 9.0967%;
}
.flag-st {
  background-position: center 9.32237%;
}
.flag-sn {
  background-position: center 9.5426%;
}
.flag-sc {
  background-position: left 9.7628%;
}
.flag-sl {
  background-position: center 9.9845%;
}
.flag-so {
  background-position: center 10.2052%;
}
.flag-za {
  background-position: left 10.4269%;
}
.flag-ss {
  background-position: left 10.6486%;
}
.flag-sd {
  background-position: center 10.8703%;
}
.flag-sr {
  background-position: center 11.0945%;
}
.flag-sz {
  background-position: center 11.3135%;
}
.flag-tg {
  background-position: left 11.5354%;
}
.flag-tn {
  background-position: center 11.7593%;
}
.flag-ug {
  background-position: center 11.9799%;
}
.flag-tz {
  background-position: center 12.2005%;
}
.flag-eh {
  background-position: center 12.4222%;
}
.flag-ye {
  background-position: center 12.644%;
}
.flag-zm {
  background-position: center 12.8664%;
}
.flag-zw {
  background-position: left 13.0873%;
}
.flag-ai {
  background-position: center 13.309%;
}
.flag-ag {
  background-position: center 13.5307%;
}
.flag-ar {
  background-position: center 13.7524%;
}
.flag-aw {
  background-position: left 13.9741%;
}
.flag-bs {
  background-position: left 14.1958%;
}
.flag-bb {
  background-position: center 14.4175%;
}
.flag-bq {
  background-position: center 14.6415%;
}
.flag-bz {
  background-position: center 14.8609%;
}
.flag-bm {
  background-position: center 15.0826%;
}
.flag-bo {
  background-position: center 15.306%;
}
.flag-vg {
  background-position: center 15.528%;
}
.flag-br {
  background-position: center 15.7496%;
}
.flag-ca {
  background-position: center 15.9694%;
}
.flag-ky {
  background-position: center 16.1911%;
}
.flag-cl {
  background-position: left 16.4128%;
}
.flag-co {
  background-position: left 16.6345%;
}
.flag-km {
  background-position: center 16.8562%;
}
.flag-cr {
  background-position: center 17.0779%;
}
.flag-cu {
  background-position: left 17.2996%;
}
.flag-cw {
  background-position: center 17.5213%;
}
.flag-dm {
  background-position: center 17.743%;
}
.flag-do {
  background-position: center 17.968%;
}
.flag-ec {
  background-position: center 18.1864%;
}
.flag-sv {
  background-position: center 18.4081%;
}
.flag-fk {
  background-position: center 18.6298%;
}
.flag-gf {
  background-position: center 18.8515%;
}
.flag-gl {
  background-position: left 19.0732%;
}
.flag-gd {
  background-position: center 19.2987%;
}
.flag-gp {
  background-position: center 19.518%;
}
.flag-gt {
  background-position: center 19.7383%;
}
.flag-gy {
  background-position: center 19.96%;
}
.flag-ht {
  background-position: center 20.1817%;
}
.flag-hn {
  background-position: center 20.4034%;
}
.flag-jm {
  background-position: center 20.6241%;
}
.flag-mq {
  background-position: center 20.8468%;
}
.flag-mx {
  background-position: center 21.0685%;
}
.flag-ms {
  background-position: center 21.2902%;
}
.flag-ni {
  background-position: center 21.5119%;
}
.flag-pa {
  background-position: center 21.7336%;
}
.flag-py {
  background-position: center 21.9553%;
}
.flag-pe {
  background-position: center 22.177%;
}
.flag-pr {
  background-position: left 22.4002%;
}
.flag-bl {
  background-position: center 22.6204%;
}
.flag-kn {
  background-position: center 22.8421%;
}
.flag-lc {
  background-position: center 23.0638%;
}
.flag-pm {
  background-position: center 23.2855%;
}
.flag-vc {
  background-position: center 23.5072%;
}
.flag-sx {
  background-position: left 23.732%;
}
.flag-tt {
  background-position: center 23.9506%;
}
.flag-tc {
  background-position: center 24.1723%;
}
.flag-us {
  background-position: center 24.392%;
}
.flag-vi {
  background-position: center 24.6157%;
}
.flag-uy {
  background-position: left 24.8374%;
}
.flag-ve {
  background-position: center 25.0591%;
}
.flag-ab {
  background-position: center 25.279%;
}
.flag-af {
  background-position: center 25.5025%;
}
.flag-az {
  background-position: center 25.7242%;
}
.flag-bd {
  background-position: center 25.9459%;
}
.flag-bt {
  background-position: center 26.1676%;
}
.flag-bn {
  background-position: center 26.3885%;
}
.flag-kh {
  background-position: center 26.611%;
}
.flag-cn {
  background-position: left 26.8327%;
}
.flag-ge {
  background-position: center 27.0544%;
}
.flag-hk {
  background-position: center 27.2761%;
}
.flag-in {
  background-position: center 27.4978%;
}
.flag-id {
  background-position: center 27.7195%;
}
.flag-jp {
  background-position: center 27.9412%;
}
.flag-kz {
  background-position: center 28.1615%;
}
.flag-la {
  background-position: center 28.3846%;
}
.flag-mo {
  background-position: center 28.6063%;
}
.flag-my {
  background-position: center 28.829%;
}
.flag-mv {
  background-position: center 29.0497%;
}
.flag-mn {
  background-position: left 29.2714%;
}
.flag-mm {
  background-position: center 29.4931%;
}
.flag-np {
  background-position: left 29.7148%;
}
.flag-kp {
  background-position: left 29.9365%;
}
.flag-mp {
  background-position: center 30.1582%;
}
.flag-pw {
  background-position: center 30.3799%;
}
.flag-pg {
  background-position: center 30.6016%;
}
.flag-ph {
  background-position: left 30.8233%;
}
.flag-sg {
  background-position: left 31.045%;
}
.flag-kr {
  background-position: center 31.2667%;
}
.flag-lk {
  background-position: right 31.4884%;
}
.flag-tw {
  background-position: left 31.7101%;
}
.flag-tj {
  background-position: center 31.9318%;
}
.flag-th {
  background-position: center 32.1535%;
}
.flag-tl {
  background-position: left 32.3752%;
}
.flag-tm {
  background-position: center 32.5969%;
}
.flag-vn {
  background-position: center 32.8186%;
}
.flag-ax {
  background-position: center 33.0403%;
}
.flag-al {
  background-position: center 33.25975%;
}
.flag-ad {
  background-position: center 33.4837%;
}
.flag-am {
  background-position: center 33.7054%;
}
.flag-at {
  background-position: center 33.9271%;
}
.flag-by {
  background-position: left 34.1488%;
}
.flag-be {
  background-position: center 34.3705%;
}
.flag-ba {
  background-position: center 34.5922%;
}
.flag-bg {
  background-position: center 34.8139%;
}
.flag-hr {
  background-position: center 35.0356%;
}
.flag-cy {
  background-position: center 35.2555%;
}
.flag-cz {
  background-position: left 35.479%;
}
.flag-dk {
  background-position: center 35.7007%;
}
.flag-ee {
  background-position: center 35.9224%;
}
.flag-fo {
  background-position: center 36.1441%;
}
.flag-fi {
  background-position: center 36.3658%;
}
.flag-fr {
  background-position: center 36.5875%;
}
.flag-de {
  background-position: center 36.8092%;
}
.flag-gi {
  background-position: center 37.0309%;
}
.flag-gr {
  background-position: left 37.2526%;
}
.flag-gg {
  background-position: center 37.4743%;
}
.flag-hu {
  background-position: center 37.696%;
}
.flag-is {
  background-position: center 37.9177%;
}
.flag-ie {
  background-position: center 38.1394%;
}
.flag-im {
  background-position: center 38.3611%;
}
.flag-it {
  background-position: center 38.5828%;
}
.flag-je {
  background-position: center 38.8045%;
}
.flag-xk {
  background-position: center 39.0262%;
}
.flag-lv {
  background-position: center 39.2479%;
}
.flag-li {
  background-position: left 39.4696%;
}
.flag-lt {
  background-position: center 39.6913%;
}
.flag-lu {
  background-position: center 39.913%;
}
.flag-mt {
  background-position: left 40.1347%;
}
.flag-md {
  background-position: center 40.3564%;
}
.flag-mc {
  background-position: center 40.5781%;
}
.flag-me {
  background-position: center 40.7998%;
}
.flag-nl {
  background-position: center 41.0215%;
}
.flag-mk {
  background-position: center 41.2432%;
}
.flag-no {
  background-position: center 41.4649%;
}
.flag-pl {
  background-position: center 41.6866%;
}
.flag-pt {
  background-position: center 41.9083%;
}
.flag-ro {
  background-position: center 42.13%;
}
.flag-ru {
  background-position: center 42.3517%;
}
.flag-sm {
  background-position: center 42.5734%;
}
.flag-rs {
  background-position: center 42.7951%;
}
.flag-sk {
  background-position: center 43.0168%;
}
.flag-si {
  background-position: center 43.2385%;
}
.flag-es {
  background-position: left 43.4602%;
}
.flag-se {
  background-position: center 43.6819%;
}
.flag-ch {
  background-position: center 43.9036%;
}
.flag-tr {
  background-position: center 44.1253%;
}
.flag-ua {
  background-position: center 44.347%;
}
.flag-gb {
  background-position: center 44.5687%;
}
.flag-va {
  background-position: right 44.7904%;
}
.flag-bh {
  background-position: center 45.0121%;
}
.flag-ir {
  background-position: center 45.2338%;
}
.flag-iq {
  background-position: center 45.4555%;
}
.flag-il {
  background-position: center 45.6772%;
}
.flag-kw {
  background-position: left 45.897%;
}
.flag-jo {
  background-position: left 46.1206%;
}
.flag-kg {
  background-position: center 46.3423%;
}
.flag-lb {
  background-position: center 46.561%;
}
.flag-om {
  background-position: left 46.7857%;
}
.flag-pk {
  background-position: center 47.0074%;
}
.flag-ps {
  background-position: center 47.2291%;
}
.flag-qa {
  background-position: center 47.4508%;
}
.flag-sa {
  background-position: center 47.6725%;
}
.flag-sy {
  background-position: center 47.8942%;
}
.flag-ae {
  background-position: center 48.1159%;
}
.flag-uz {
  background-position: left 48.3376%;
}
.flag-as {
  background-position: right 48.5593%;
}
.flag-au {
  background-position: center 48.781%;
}
.flag-cx {
  background-position: center 49.002%;
}
.flag-cc {
  background-position: center 49.2244%;
}
.flag-ck {
  background-position: center 49.4445%;
}
.flag-fj {
  background-position: center 49.6678%;
}
.flag-pf {
  background-position: center 49.8895%;
}
.flag-gu {
  background-position: center 50.1112%;
}
.flag-ki {
  background-position: center 50.3329%;
}
.flag-mh {
  background-position: left 50.5546%;
}
.flag-fm {
  background-position: center 50.7763%;
}
.flag-nc {
  background-position: center 50.998%;
}
.flag-nz {
  background-position: center 51.2197%;
}
.flag-nr {
  background-position: left 51.4414%;
}
.flag-nu {
  background-position: center 51.6631%;
}
.flag-nf {
  background-position: center 51.8848%;
}
.flag-ws {
  background-position: left 52.1065%;
}
.flag-sb {
  background-position: left 52.3282%;
}
.flag-tk {
  background-position: center 52.5499%;
}
.flag-to {
  background-position: left 52.7716%;
}
.flag-tv {
  background-position: center 52.9933%;
}
.flag-vu {
  background-position: left 53.215%;
}
.flag-wf {
  background-position: center 53.4385%;
}
.flag-aq {
  background-position: center 53.6584%;
}
.flag-eu {
  background-position: center 53.875%;
}
.flag-jr {
  background-position: center 54.099%;
}
.flag-oly {
  background-position: center 54.32%;
}
.flag-un {
  background-position: center 54.54%;
}
.flag-td.ff-round,
.flag-gn.ff-round,
.flag-ci.ff-round,
.flag-ml.ff-round,
.flag-ng.ff-round,
.flag-be.ff-round,
.flag-fr.ff-round,
.flag-ie.ff-round,
.flag-it.ff-round,
.flag-ro.ff-round {
  background-size: 100% 50000%;
}

.flag.ff-sm {
  width: 18px;
  height: 11px;
}
.flag.ff-md {
  width: 27px;
  height: 17px;
}
.flag.ff-lg {
  width: 42px;
  height: 27px;
}
.flag.ff-xl {
  width: 60px;
  height: 37px;
}

/* ff-round = circular icons */
.ff-round {
  background-size: 160%;
  background-clip: content-box;
  border-radius: 50%;
}
.ff-round.ff-sm {
  width: 12px;
  height: 12px;
}
.ff-round.ff-md {
  width: 18px;
  height: 18px;
}
.ff-round.ff-lg {
  width: 24px;
  height: 24px;
}
.ff-round.ff-xl {
  width: 32px;
  height: 32px;
}
