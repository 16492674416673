.default_modal {
  position: relative !important;
  // background-color: red;
  .close-button {
    position: absolute;
    top: 21px;
    right: 16px;
    z-index: 5;
  }

  .mat-dialog-title {
    padding-right: 60px;
    font-weight: bold;
  }
}

.mat-dialog-container {
  transition: none !important;
  border-radius: 10px !important;
  display: flex;
}

.mat-dialog-container > * {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;
}

.mat-dialog-content {
  display: flex !important;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex: 1;
  max-height: unset !important;
  border-top: 1px solid rgb(205, 205, 205);
  padding-top: 10px;
}

.default_backdrop {
  background-color: rgba(0, 115, 198, 0.5);
  backdrop-filter: blur(12.7px);
  -webkit-backdrop-filter: blur(12.7px);
}

.highligth {
  position: relative;
  z-index: 1001;
}
