@use '@angular/material' as mat;
@import './assets/css/palette.scss';
@import './assets/css/familycare.scss';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './assets/css/global.scss';
@import './assets/css/modal.scss';
@import './assets/iconfonts/RemixIcons/fonts/remixicon.css';
@import './assets/iconfonts/tabler-icons/webfont/tabler-icons.css';

@import url('./assets/images/country/flagSprite.css');
@import url('./assets/css/font.scss');
@import '@angular/material/theming';

.material-icons {
  font-family: 'Material Icons' !important;
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Poppins, sans-serif',
  $display-4: mat-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3: mat-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2: mat-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1: mat-typography-level(34px, 40px, 400),
  $headline: mat-typography-level(24px, 32px, 400),
  $title: mat-typography-level(20px, 32px, 500),
  $subheading-2: mat-typography-level(16px, 28px, 400),
  $subheading-1: mat-typography-level(15px, 24px, 400),
  $body-2: mat-typography-level(14px, 24px, 500),
  $body-1: mat-typography-level(14px, 20px, 400),
  $caption: mat-typography-level(12px, 20px, 400),
  $button: mat-typography-level(14px, 14px, 500),
  $input: mat-typography-level(inherit, 1.125, 400),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

$primary: mat.define-palette($md-blue, 700);
$familycare: mat.define-palette($md-green, 700);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$v2_webclient-primary: mat.define-palette($md-blue);
$familycare-primary: mat.define-palette($md-green);
$v2_webclient-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$v2_webclient-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$v2_webclient-theme: mat.define-light-theme(
  (
    color: (
      primary: $v2_webclient-primary,
      accent: $v2_webclient-accent,
      warn: $v2_webclient-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($v2_webclient-theme);

.familycare {
  $familycare-theme: mat.define-light-theme(
    (
      color: (
        primary: $familycare-primary,
        accent: $v2_webclient-accent,
        warn: $v2_webclient-warn,
      ),
      density: 0,
      typography: mat.define-typography-config(),
    )
  );
  @include mat.all-legacy-component-themes($familycare-theme);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 * Optional: Makes the sample page fill the window.
 */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/*
 * Always set the map height explicitly to define the size of the div element
 * that contains the map.
 */

.gm-style-iw {
  max-width: 360px !important;
}

.text-highlight {
  color: #00ccad;
  font-weight: bold;
  padding: 0rem 0.25rem;
}

#map {
  height: 100%;
  width: 100%;
}

* {
  transition: all 0.3s ease-in-out;
}

.back-confetti {
  background-image: url('./assets/gif/confetti.gif');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

[multiple],
[type='date'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
select,
textarea {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: none;
  border-color: inherit;
  border-radius: none;
  border-width: inherit;
  font-size: none;
  line-height: none;
  padding: inherit;
  border-width: 0;
}

[multiple]:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='email']:focus,
[type='month']:focus,
[type='number']:focus,
[type='password']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='text']:focus,
[type='time']:focus,
[type='url']:focus,
[type='week']:focus,
select:focus,
textarea:focus {
  --tw-ring-color: transparent !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: none;
  border-color: inherit;
  border-radius: none;
  border-width: 0;
  font-size: none;
  line-height: none;
  box-shadow: none;
}

.admin-table {
  min-width: 650px !important;
  overflow-x: scroll;
}

.filter-light-blue {
  filter: invert(32%) sepia(45%) saturate(1442%) hue-rotate(188deg)
    brightness(102%) contrast(81%);
}

.filter-blue {
  filter: invert(30%) sepia(68%) saturate(3109%) hue-rotate(188deg)
    brightness(88%) contrast(101%);
}

.filter-white {
  filter: invert(86%) sepia(64%) saturate(0%) hue-rotate(336deg)
    brightness(102%) contrast(104%);
}

.glass-morphism {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(12.1px);
  -webkit-backdrop-filter: blur(12.1px);
}

.glass-morphism-blue {
  /* From https://css.glass */
  background: rgba(0, 115, 198, 0.73);
  backdrop-filter: blur(12.7px);
  -webkit-backdrop-filter: blur(12.7px);
}

.glass-morphism-bt {
  /* From https://css.glass */
  background: rgba(0, 115, 198, 0.4);
  backdrop-filter: blur(12.7px);
  -webkit-backdrop-filter: blur(12.7px);
}

.glass-morphism-black {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12.7px);
  -webkit-backdrop-filter: blur(12.7px);
}

html,
body {
  height: 100%;
}

.button-loading {
  position: relative;

  .mat-button-wrapper {
    visibility: hidden;
  }
}

::-webkit-scrollbar {
  height: 5px;
  width: 3px;
}

::-webkit-scrollbar-track {
  @apply bg-kimbo-blue-light;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  @apply bg-kimbo-blue bg-opacity-60;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  @apply bg-kimbo-blue-light;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  @apply bg-kimbo-blue bg-opacity-60;
  border-radius: 12px;
}

.apexcharts-legend-series {
  @apply flex items-center justify-start gap-3;
}

.r_triangle {
  height: 8px;
  width: 8px;
  background: #c18b03;
  position: absolute;
  top: -8px;
  right: 0px;
  clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
}

.highligth {
  position: relative;
  z-index: 1001;
}

.material-modal-container {
  overflow: visible !important;
  position: relative;
}

.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 32px 0 #fff, -32px 0 #fff;
  position: relative;
  animation: flash 0.3s ease-in infinite alternate;
}

.loader::before,
.loader::after {
  content: '';
  position: absolute;
  left: -64px;
  top: 0;
  background: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform-origin: 35px -35px;
  transform: rotate(45deg);
  animation: hitL 0.3s ease-in infinite alternate;
}

/*.mat-dialog-container {
  overflow: visible!important;
}*/

.loader::after {
  left: 64px;
  transform: rotate(-45deg);
  transform-origin: -35px -35px;
  animation: hitR 0.3s ease-out infinite alternate;
}

@keyframes flash {
  0%,
  100% {
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 32px 0 rgba(255, 255, 255, 0.25),
      -32px 0 rgba(255, 255, 255, 0.25);
  }

  25% {
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 32px 0 rgba(255, 255, 255, 0.25), -32px 0 rgba(255, 255, 255, 1);
  }

  50% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 32px 0 rgba(255, 255, 255, 0.25),
      -32px 0 rgba(255, 255, 255, 0.25);
  }

  75% {
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 32px 0 rgba(255, 255, 255, 1), -32px 0 rgba(255, 255, 255, 0.25);
  }
}

@keyframes hitL {
  0% {
    transform: rotate(45deg);
    background-color: rgba(255, 255, 255, 1);
  }

  25%,
  100% {
    transform: rotate(0deg);
    background-color: rgba(255, 255, 255, 0.25);
  }
}

@keyframes hitR {
  0%,
  75% {
    transform: rotate(0deg);
    background-color: rgba(255, 255, 255, 0.25);
  }

  100% {
    transform: rotate(-45deg);
    background-color: rgba(255, 255, 255, 1);
  }
}

.grecaptcha-badge {
  opacity: 0;
}

app-detail {
  display: block;
}

.map-marker-div-span {
  transform: translateX(-84px);
  display: block;
  width: 200px;
  background-color: white;
  color: #183446;
  padding: 3px;
  border-radius: 6px;
  margin-top: 2px;
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  box-shadow: 0 12px 20px 0 rgba(24, 52, 70, 0.15);
}

/* the slides */
.owl-carousel .owl-stage {
  display: flex !important;
  align-items: baseline !important;
}

.owl-carousel .owl-stage .owl-item {
  display: flex !important;
  justify-content: center;
}

::-moz-selection {
  @apply text-white;
  @apply bg-kimbo-blue;
}

::selection {
  @apply text-white;
  @apply bg-kimbo-blue;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* No-scroll class */
.no-scroll {
  overflow: hidden;
}

.custom-material-tooltip,
.custom-material-tooltip * {
  @apply bg-kimbo-blue text-white text-[14px] rounded-xl #{!important};
}

.default_bottom_sheet {
  @apply max-w-[700px] flex items-center justify-center #{!important};
}

.default_bottom_sheet mat-bottom-sheet-container {
  @apply max-h-[95vh] #{!important};
  @apply h-[100vh] w-[100vw] max-h-[100vh] max-w-[100vw] m-0 #{!important};
  @apply md:h-auto md:w-auto md:max-h-[95vh] md:max-w-[95vw] md:m-auto #{!important};
}

.default_bottom_sheet > mat-bottom-sheet-container {
  @apply rounded-t-2xl px-8 #{!important};
}

strong {
  text-transform: lowercase;
}

.interest-heart-icon {
  color: lightgray;
  transition: fill 0.3s;
}

.interest-heart-icon:hover {
  color: red;
}
